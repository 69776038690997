import { Component, Vue } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import InsuranceDialog from '@/components/shared/Dialogs/InsuranceDialog/InsuranceDialog.vue'
import { InsuranceSearchModel, InsuranceResponseModel } from '@/models/insurances-model'
import { defaultItemsPerPage, defaultItemsPerPageWithoutAll, AssuranceInvoiceMode, AssuranceInvoiceModeFilter } from '@/shared/constants/Constants'
import { InsuranceService } from '@/services/insurance-service'
import { PagedCollection } from '@/components/shared/Helpers/common-models'
import { ErrorService } from '@/services/error.service'

@Component({
  components: {
    Alert,
    Confirm,
    InsuranceDialog
  }
})
export default class InsurancesManagement extends Vue {
  private insuranceService = InsuranceService.getInstance()

  public errorMessages: string[] = []
  public searchModel: InsuranceSearchModel = InsurancesManagement.resetSearchModel()
  public items: InsuranceResponseModel[] = []

  public defaultItemsPerPageWithoutAll = defaultItemsPerPageWithoutAll
  public defaultItemsPerPage = defaultItemsPerPage
  public options: any = {}
  public totalSize = 0
  public pageCount = 0
  public isSearching = false
  public firstSearch = true
  public canPaginationTriggerSearch = false
  public editedItem: InsuranceResponseModel = InsurancesManagement.defaultInsuranceModel()
  public editDialog = false
  public showDeleteConfirm = false
  public isLoading = false
  public assuranceInvoiceModes = AssuranceInvoiceMode

  private static defaultInsuranceModel (): InsuranceResponseModel {
    return {
      id: 0,
      actif: true,
      assuranceAddresse: '',
      assuranceName: '',
      asuCp: '',
      asuGln1: '',
      asuGln2: '',
      eBillCapable: true,
      email: '',
      invoiceMode: AssuranceInvoiceMode.TarifSuisse,
      localite: '',
      npa: '',
      telephone: '',
      telephone2: ''
    }
  }

  public hksFilter = [{ value: null, text: 'Tous' }, ...AssuranceInvoiceModeFilter]

  public headers = [
    {
      text: 'ID',
      value: 'id',
      width: '5%'
    },
    {
      text: 'Nom',
      value: 'assuranceName',
      width: '15%'
    },
    {
      text: 'NPA',
      value: 'npa',
      width: '5%'
    },
    {
      text: 'Localité',
      value: 'localite',
      width: '15%'
    },
    {
      text: 'Téléphone',
      value: 'telephone',
      sortable: false,
      width: '10%'
    },
    {
      text: 'Email',
      value: 'email',
      sortable: false,
      width: '15%'
    },
    {
      text: 'Type',
      value: 'invoiceMode',
      formatter: this.getAssuranceInvoiceMode,
      width: '5%'
    },
    {
      text: 'GLN filiale',
      value: 'asuGln1',
      width: '10%'
    },
    {
      text: 'GLN principal',
      value: 'asuGln2',
      width: '10%'
    },
    {
      text: 'Actif',
      value: 'actif',
      width: '5%'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'right',
      width: '5%'
    }
  ]

  public getAssuranceInvoiceMode (mode: number) {
    switch (mode) {
      case AssuranceInvoiceMode.TarifSuisse: return 'TS'
      case AssuranceInvoiceMode.HKS: return 'HKS'
      case AssuranceInvoiceMode.SUVA: return 'SUVA'
      default: return ''
    }
  }

  private static resetSearchModel () {
    const model: InsuranceSearchModel = {
      invoiceMode: null,
      page: 1,
      pageSize: 5
    }
    return model
  }

  public async search () {
    // prevent multiple searches from being sent, since searches can be issued from clicking the search button, v-data-table options changing, ...
    if (this.isSearching) {
      return
    }
    this.isSearching = true
    if (this.firstSearch) {
      this.firstSearch = false
      this.options.sortBy = ['assuranceName']
      this.options.sortDesc = [false]
    }
    await this.insuranceService.searchInsurances(this.searchModel, this.options).then((p: PagedCollection<InsuranceResponseModel>) => {
      this.items = p.value
      this.totalSize = p.size
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isSearching = false
    })
  }

  public tableOptionsChanged () {
    if (this.canPaginationTriggerSearch) {
      this.search()
    }
  }

  public async searchButton () {
    this.canPaginationTriggerSearch = true
    await this.search()
    this.options.page = 1
    this.searchModel.page = 1
  }

  public resetSearch () {
    this.searchModel = InsurancesManagement.resetSearchModel()
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public openInsuranceDialog () {
    this.hideAlert()
    this.editedItem = InsurancesManagement.defaultInsuranceModel()
    this.editDialog = true
  }

  public editItem (item: InsuranceResponseModel) {
    this.editedItem = item
    this.editDialog = true
  }

  public displayConfirmDialog (item: InsuranceResponseModel) {
    this.editedItem = item
    this.showDeleteConfirm = true
  }

  public async confirmCallback (value: boolean) {
    this.showDeleteConfirm = false
    if (value) {
      this.isLoading = true
      await this.deleteItem(this.editedItem.id)
        .then(async () => {
          this.hideAlert()
          await this.search()
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.isLoading = false
          this.editedItem = InsurancesManagement.defaultInsuranceModel()
        })
    }
  }

  private async deleteItem (id: number) {
    this.insuranceService.deleteInsurance(id).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(async () => {
      await this.search()
    })
  }

  public closeInsuranceDialog () {
    this.isLoading = false
    this.editedItem = InsurancesManagement.defaultInsuranceModel()
    this.hideAlert()
    this.editDialog = false
    this.search()
  }

  public async save () {
    this.hideAlert()
    this.isLoading = true
    await this.insuranceService.addUpdateInsurance(this.editedItem)
      .then(async () => {
        this.editDialog = false
        if (!this.firstSearch) {
          await this.search()
        }
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isLoading = false
      })
  }
}
