import { Component, Vue } from 'vue-property-decorator'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import DoctorDialog from '@/components/shared/Dialogs/DoctorDialog/DoctorDialog.vue'
import { DoctorSearchModel, DoctorResponseModel } from '@/models/doctors-model'
import { defaultItemsPerPage, defaultItemsPerPageWithoutAll } from '@/shared/constants/Constants'
import { DoctorService } from '@/services/doctor-service'
import { PagedCollection } from '@/components/shared/Helpers/common-models'
import { ErrorService } from '@/services/error.service'

@Component({
  components: {
    Alert,
    Confirm,
    DoctorDialog
  }
})
export default class DoctorsManagement extends Vue {
  private doctorService = DoctorService.getInstance()

  public errorMessages: string[] = []
  public searchModel: DoctorSearchModel = DoctorsManagement.resetSearchModel()
  public items: DoctorResponseModel[] = []

  public defaultItemsPerPageWithoutAll = defaultItemsPerPageWithoutAll
  public defaultItemsPerPage = defaultItemsPerPage
  public options: any = {}
  public totalSize = 0
  public pageCount = 0
  public isSearching = false
  public firstSearch = true
  public canPaginationTriggerSearch = false
  public editedItem: DoctorResponseModel = DoctorsManagement.defaultDoctorModel()
  public editDialog = false
  public showDeleteConfirm = false
  public isLoading = false

  private static defaultDoctorModel (): DoctorResponseModel {
    return {
      id: 0,
      nom: '',
      prenom: '',
      npa: '',
      ville: '',
      rcc: '',
      ean: '',
      telephone: '',
      email: '',
      isActive: true,
      employeur: '',
      specialite: '',
      mobile: '',
      district: '',
      address: ''
    }
  }

  public headers = [
    {
      text: 'ID',
      value: 'id',
      width: '5%'
    },
    {
      text: 'Nom',
      value: 'nom',
      width: '15%'
    },
    {
      text: 'Prénom',
      value: 'prenom',
      width: '15%'
    },
    {
      text: 'NPA',
      value: 'npa',
      width: '5%'
    },
    {
      text: 'Localité',
      value: 'ville',
      width: '15%'
    },
    {
      text: 'Téléphone',
      value: 'telephone',
      sortable: false,
      width: '10%'
    },
    {
      text: 'Email',
      value: 'email',
      sortable: false,
      width: '15%'
    },
    {
      text: 'GLN',
      value: 'ean',
      width: '5%'
    },
    {
      text: 'RCC',
      value: 'rcc',
      width: '5%'
    },
    {
      text: 'Actif',
      value: 'isActive',
      width: '5%'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'right',
      width: '5%'
    }
  ]

  private static resetSearchModel () {
    const model: DoctorSearchModel = {
      page: 1,
      pageSize: 5
    }
    return model
  }

  public async search () {
    // prevent multiple searches from being sent, since searches can be issued from clicking the search button, v-data-table options changing, ...
    if (this.isSearching) {
      return
    }
    this.isSearching = true
    if (this.firstSearch) {
      this.firstSearch = false
      this.options.sortBy = ['nom', 'prenom']
      this.options.sortDesc = [false, false]
    }
    await this.doctorService.searchDoctors(this.searchModel, this.options).then((p: PagedCollection<DoctorResponseModel>) => {
      this.items = p.value
      this.totalSize = p.size
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isSearching = false
    })
  }

  public tableOptionsChanged () {
    if (this.canPaginationTriggerSearch) {
      this.search()
    }
  }

  public async searchButton () {
    this.canPaginationTriggerSearch = true
    await this.search()
    this.options.page = 1
    this.searchModel.page = 1
  }

  public resetSearch () {
    this.searchModel = DoctorsManagement.resetSearchModel()
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public openDoctorDialog () {
    this.hideAlert()
    this.editedItem = DoctorsManagement.defaultDoctorModel()
    this.editDialog = true
  }

  public editItem (item: DoctorResponseModel) {
    this.editedItem = item
    this.editDialog = true
  }

  public displayConfirmDialog (item: DoctorResponseModel) {
    this.editedItem = item
    this.showDeleteConfirm = true
  }

  public async confirmCallback (value: boolean) {
    this.showDeleteConfirm = false
    if (value) {
      this.isLoading = true
      await this.deleteItem(this.editedItem.id)
        .then(async () => {
          this.hideAlert()
          await this.search()
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.isLoading = false
          this.editedItem = DoctorsManagement.defaultDoctorModel()
        })
    }
  }

  private async deleteItem (id: number) {
    this.doctorService.deleteDoctor(id).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(async () => {
      await this.search()
    })
  }

  public closeDoctorDialog () {
    this.isLoading = false
    this.editedItem = DoctorsManagement.defaultDoctorModel()
    this.hideAlert()
    this.editDialog = false
    this.search()
  }

  public async save () {
    this.hideAlert()
    this.isLoading = true
    await this.doctorService.addUpdateDoctor(this.editedItem)
      .then(async () => {
        this.editDialog = false
        if (!this.firstSearch) {
          await this.search()
        }
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isLoading = false
      })
  }
}
