import Commons from '@/components/shared/Helpers/commons'
import http from '@/http-client'
import { PagedCollection } from '@/components/shared/Helpers/common-models'
import { DoctorSearchModel, DoctorResponseModel } from '@/models/doctors-model'
import { DataOptions } from 'vuetify/types'

export class DoctorService {
  private static instance: DoctorService

  public static getInstance (): DoctorService {
    if (!DoctorService.instance) {
      DoctorService.instance = new DoctorService()
    }
    return DoctorService.instance
  }

  public async searchDoctors (searchModel: DoctorSearchModel, options: DataOptions) {
    const queryString: string[] = []
    Commons.PushOptionsToQuerystring(queryString, options)
    const qs = `?${queryString.join('&')}`
    const response = await http.post(`/doctors/search${qs}`, searchModel)
    return (response.data) as PagedCollection<DoctorResponseModel>
  }

  public async deleteDoctor (id: number) {
    return http.delete(`/doctors/${id}`)
  }

  public async addUpdateDoctor (model: DoctorResponseModel) {
    const response = await http.post('/doctors', model)
    return response.data as number
  }
}
