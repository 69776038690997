import { LayoutService } from '@/services/layout-service'
import { Component, Vue } from 'vue-property-decorator'
import DoctorsManagement from '@/views/Administration/ListsManagement/DoctorsManagement/DoctorsManagement.vue'
import InsurancesManagement from '@/views/Administration/ListsManagement/InsurancesManagement/InsurancesManagement.vue'
import { AdministrationHelper } from '../AdministrationHelper'

@Component({
  components: {
    DoctorsManagement,
    InsurancesManagement
  }
})
export default class ListsManagement extends Vue {
  public tab: any = null
  public tabs = ['Médecins', 'Assurances']
  private layoutService = LayoutService.getInstance()

  public mounted () {
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
  }
}
