import Commons from '@/components/shared/Helpers/commons'
import http from '@/http-client'
import { PagedCollection } from '@/components/shared/Helpers/common-models'
import { InsuranceSearchModel, InsuranceResponseModel } from '@/models/insurances-model'
import { DataOptions } from 'vuetify/types'

export class InsuranceService {
  private static instance: InsuranceService

  public static getInstance (): InsuranceService {
    if (!InsuranceService.instance) {
      InsuranceService.instance = new InsuranceService()
    }
    return InsuranceService.instance
  }

  public async searchInsurances (searchModel: InsuranceSearchModel, options: DataOptions) {
    const queryString: string[] = []
    Commons.PushOptionsToQuerystring(queryString, options)
    const qs = `?${queryString.join('&')}`
    const response = await http.post(`/insurances/search${qs}`, searchModel)
    return (response.data) as PagedCollection<InsuranceResponseModel>
  }

  public async deleteInsurance (id: number) {
    return http.delete(`/insurances/${id}`)
  }

  public async addUpdateInsurance (model: InsuranceResponseModel) {
    const response = await http.post('/insurances', model)
    return response.data as number
  }
}
